@import 'styles/styles';

.overview {
  width: 100%;
  padding-left: 20px;
  h5 {
    border-bottom: 1px solid $LIGHT_GREY;
    padding-bottom: 3px;
    margin-bottom: 29px;
  }
  .links {
    a {
      display: block;
      text-decoration: none;
      color: $TURQUOISE;
      font-weight: bold;
    }
  }

}