@import "styles/styles";

.onboarding_container {
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.breadcrumbs {
  color: $WHITE;

  .step_selected {
    color: $TURQUOISE;
    font-weight: bold;
  }

  .step_completed {
    color: $TURQUOISE;
    cursor: pointer;
    font-weight: bold;
  }

  li::before {
    height: 10px;
    width: 10px;
  }
}

.action_buttons {
  display: flex;
  justify-content: space-between;

}

.buyer_client_container {
  h5 {
    margin-bottom: 18px;
  }
}

.member_type_container {
  padding-top: 45px;
  box-sizing: border-box;

  .member_type_card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    background-position: left;
    background-image: url(../../../../resources/images/svg/membership_type_bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: $PURPLE;
    border-radius: 10px;
    color: $WHITE;
    max-width: 540px;
    width: 100%;
    min-height: 130px;
    padding: 9px 15% 36px;
    margin-bottom: 16px;

    span {
      font-size: 14px;
      opacity: 0.5;
      font-weight: bold;
    }

    h4 {
      margin-bottom: 5px;
    }
  }

  .member_type_card_selected {
    background-color: rgba(130, 38, 118, 0.8);
  }
}

.error {
  color: $ERROR_RED;
  font-size: 0.75rem;
}

.supplier_container {
  h5 {
    margin-bottom: 18px;
  }

  > p {
    max-width: 550px;
    margin-bottom: 45px;
  }

  .faqs {
    .faq_content {
      margin-bottom: 9px;
      box-shadow: none;
      border-radius: 5px;
    }

    .faq_content:before {
      background-color: transparent !important;
      top: 0px !important;
    }

    .faq_question {
      background-color: $LIGHT_GREY;
      border-radius: 5px;

      .faq_question_text {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: $BLACK;
      }

      img {
        width: 14px;
        height: 13px;
      }
    }

    .faq_question[aria-expanded="true"] {
      background-color: $PURPLE;
      border-radius: 5px 5px 0px 0px;

      .faq_question_text {
        color: $WHITE !important;
      }
    }

    .faq_answer {
      border-radius: 0px 0px 5px 5px;
      padding: 18px 27px 27px;
      background-color: $PURPLE_GREY;

      ul {
        margin: 0px;
      }
    }
  }

  .single_button_container {
    text-align: right;
    margin-top: 45px;
  }
}

.inner_container {
  padding-top: 45px;
  box-sizing: border-box;

  label {
    color: $BLACK;
    font-size: 13px;
    transform: none;
    margin-bottom: 2px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  hr {
    border: 1px solid $LIGHT_GREY;
    margin: 18px 0px;
  }
}

.consent_checkboxes {
  label {
    display: block;
  }

  a {
    text-decoration: none;
    color: $TURQUOISE;
    font-weight: bold;
  }
}


.card_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .plan_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 9px;
    span {
      font-weight: bold;
      margin-bottom: 18px;
      display: block;
    }
  }

}

.paragraph_input {
  //margin-bottom: 45px;
  outline: none;
  max-width: 100%;
  padding: 9px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  border: 1px solid #CDCDCD;
  border-radius: 5px;
  min-height: 165px;
  width: 100%;
}

.heading {
  width: 100%;
  display: block;

  img {
    margin-left: 9px;
    vertical-align: middle;
  }
}

.checkbox_container {
  margin-top: 36px;

  span {
    font-family: 'Poppins', sans-serif
  }

  label {
    width: 49%;
  }
}

.onboarding_form {
  max-width: 650px;
  width: 100%;
  min-height: 60vh;

  > button {
    display: block;
  }

  hr {
    border: 1px solid $LIGHT_GREY;
    margin: 18px 0px;
  }

  label {
    color: $BLACK;
    font-size: 13px;
    transform: none;
    margin-bottom: 2px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .review_section {
    margin: 45px 0px;

    > button {
      display: block;
    }

    .paragraph_input {
      margin-bottom: 0px;
    }
  }

  .agree_section {
    border-top: 1px solid $LIGHT_GREY;
    padding: 45px 0px;
    box-sizing: border-box;

  }

  > div {
    margin-bottom: 27px;
  }

  .no_margin_input {
    margin-bottom: 0px !important;
  }
}

.file_input {
  label {
    color: $TURQUOISE;
    padding: 7px 0;
    cursor: pointer;
  }
}

.logo_image {
  > div {
    border-radius: 0px !important;
    background-size: contain !important;
  }
}

.category_container {
  box-shadow: none;
  margin-bottom: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0px 11px;


  .category_item {
    box-shadow: none;

    .category_label {
      label{
        margin-right: 0px;
      }
      span {
        font-weight: bold;

      }

    }

  }
}

.industry_form {
  margin-bottom: 36px;

  .subsub_container {
    display: block;

    > label {
      width: 49%
    }
  }

  .plain_subindustry_row {
    //border: 1px solid black;
    margin-bottom: 0px;
    padding: 12px 18px;
    box-shadow: none;
  }
  .remove_button {
      text-align: right;
      span {
          color: red;
      }
  }
}

.other_insurance_heading {
  padding-top: 18px;

  img {
    margin-left: 9px;
    vertical-align: middle;
  }
}

.insurance_row {
  border-bottom: 1px solid $LIGHT_GREY;

  > div {
    margin-bottom: 0px;
  }

  button {
    color: $ERROR_RED !important;
    margin-bottom: 27px;
  }
}

.ethnic_owned_row {
}

.employed_form {
  hr {
    margin: 0px 0px 18px;
  }
}

.health_safety_row {
  border-bottom: 1px solid $LIGHT_GREY;

  button {
    font-size: 13px;
    color: $ERROR_RED !important;
    padding: 0 2px;
  }
}


.ownership_form {
  > div {
    margin-bottom: 0px !important;
  }
}

.owner_row {

  > div {
    vertical-align: top;
  }

  > button {
    font-size: 13px;
    color: $ERROR_RED !important;
    padding: 0 2px;
  }

}

.add_button_owners{
  margin-bottom: 9px;
}
.no_affiliations{
  margin: 9px 0px;
  font-weight: bold;
  font-size: 13px;
  color: $PURPLE;
}

.affiliation_row {
  > div {
    vertical-align: top;
  }

  > button {
    font-size: 13px;
    color: $ERROR_RED !important;
    padding: 0 2px;
  }
}


.contact_row {
  border-bottom: 1px solid $LIGHT_GREY;

  .contact_input_wrapper {

  }

  > button {
    font-size: 13px;
    color: $ERROR_RED !important;
    padding: 0 2px;
  }
}

.profile_contact {
  margin-top: 36px;
}

.reference_row {
  border-bottom: 1px solid $LIGHT_GREY;

  button {
    font-size: 13px;
    color: $ERROR_RED !important;
    padding: 0 2px;
  }
}

.label_tooltip_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  margin-bottom: 0px !important;
  padding-top: 9px;
  box-sizing: border-box;
}

.button_container {
  display: flex;
  justify-content: space-between;
}

.full_input {
  width: 100%;
  margin-bottom: 27px;
  position: relative;
}

.half_input {
  vertical-align: top;
  width: 49%;
  display: inline-block;
  margin-bottom: 27px;
}

.first {
  margin-right: 2%;
}

.tooltip_input {
  padding-top: 3px;
}

.third_input {
  width: 60%;
  margin-bottom: 0px !important;
  display: inline-block;
}

.third_input_small {
  width: 18%;
  margin-bottom: 0px !important;
  display: inline-block;

}

.add_button {
  display: block;
  margin-bottom: 27px;
}

@media screen and (max-width: 1100px) {
  .card_container {
    display: block;
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
  .onboarding_form {
    .checkbox_container {
      label {
        width: 100%;
      }
    }
  }
  .industry_form {

    .subsub_container {
      > label {
        width: 100%;
      }
    }
  }
  .other_insurance_heading {
    img {
     display: none;
    }
  }

  .heading {
    img {
      display: none;
    }
  }
  .label_tooltip_container {
    img {
      display: none;

    }
  }
  .full_input {
    width: 100%;
    margin-bottom: 18px !important;
  }

  .half_input {
    width: 100%;
    display: block;
    margin-bottom: 18px !important;
  }
  .third_input {
    vertical-align: top;
    width: 100%;
    margin-bottom: 18px !important;
    display: block;
  }

  .add_button {
    display: block;
    margin-bottom: 9px;
  }

  .third_input_small {
    width: 100%;
    display: block;
    margin-bottom: 18px !important;
  }
  .checkbox_container {
    label {
      width: 100%;
    }
  }

}