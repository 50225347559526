@import "styles/styles";

.operations_report_container {
  margin-bottom: 45px;
  width: 100%;

  table {
    width: 100%;
    text-align: left;

    td, th {
      border-bottom: 1px solid $LIGHT_GREY;
    }
  }

  .table_report {
    padding-top: 18px;
    margin-bottom: 45px;

  }

  .ownership_graph_container {
    padding-top: 27px;

    .ownership_graph {
      display: inline-flex;
      border-radius: 8px;
      width: 100%;
      background: $PURPLE_GREY;
      color: $WHITE;

      .male {
        border-radius: 8px 0 0 8px;
        background-color: $PURPLE;
        padding: 3px 0 3px 18px;
      }

      .female {
        background-color:#A8679F;
        padding: 3px 0 3px 18px;
      }

      .unspecified {
        padding: 3px 0 3px 18px;
        color: $BLACK;
      }
    }

    .legend_container {
      display: inline-flex;
      width: 70%;
      justify-content: space-between;
      padding-top: 9px;

      .legend {

        .title {
          display: inline-flex;
          font-size: 14px;

          .circle {
            border-radius: 100%;
            width: 18px;
            height: 18px;
            margin-right: 9px;
          }

          .male_circle {
            background-color: $PURPLE;
          }

          .female_circle {
            background-color: #A8679F;
          }

          .unspecified_circle {
            background-color: $PURPLE_GREY;
          }
        }

        .subtitle {
          font-size: 12px;
          line-height: 12px;
          padding-left: 27px;
        }
      }

      @media screen and (max-width: 640px){
        display: block;
        width: 100%;

        .legend{
          margin-bottom: 9px;
        }
      }

    }
  }
  .categories_table{
    display: none;

    @media screen and (max-width: 640px) {
      display: block;
    }

  }

  .employment_report_container {
    margin-bottom: 27px;
    width: 100%;

    .row {
      &:last-of-type {
        padding-top: 0;
      }

      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      padding: 18px 0;

      .half {
        width: 48%;
      }

      .third {
        width: 30%;
      }

      .row_item {
        background-color: $LIGHT_GREY;
        border-radius: 8px;
        text-align: center;
        padding: 18px;

        .header {
          display: block;
          font-size: 36px;
          font-weight: bold;
        }

        .subheader {
          font-weight: bold;
        }
      }
    }

    @media screen and (max-width: 640px) {
      display: none;

      .row{
        padding: 0px;
        display: block;
        .half{
          margin: 9px 0px;
          width: 100%;
        }
        .third{
          margin: 9px 0px;
          width: 100%;
        }
      }

    }


  }

  .category_graph_container {
    width: 100%;
    margin-bottom: 27px;
    padding: 18px 0;
    display: block;

    .row {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;

      .left {
        width: 40%;
        padding: 9px 18px 9px 0;
        border-right: 1px solid $LIGHT_GREY;
      }

      .right_container {
        width: 60%;
        margin: auto 0;
        display: inline-flex;

        .right {
          padding-left: 9px;
          background-color: $TURQUOISE;
          border-radius: 0 8px 8px 0;
          height: 30px;
        }

        .category_count {
          margin: auto 0;
          padding-left: 9px;
        }
      }
    }
    @media screen and (max-width: 640px){
      display: none;
      .row{
        display: block;
        margin-bottom: 18px;

        .left{
          border-right: none;
        }
      }
    }
  }

}
