@import "styles/styles";


.resources{
  background-color: $BLUE_GREY;

  .resources_tabs_container {
    padding-top: 32px;
  }

  .resources_content{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    .resources_listings{
      margin-bottom: 45px;
      width: 100%;

    }
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT){
  .resources{
    h2 {
      text-align: center;
    }
    .resources_tabs_container {
      .resources_tabs {
        >div {
          overflow: hidden;
          > div {
            overflow-x: scroll;
            padding-bottom: 18px;
            box-sizing: content-box;
          }
        }
      }
    }
    .resources_content{
      display: block;
      .resources_listings{
        max-width: 100%;
        max-height: unset;
        overflow-y: unset;
      }
    }
  }
}