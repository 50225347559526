@import 'styles/styles';

.sidebar_content_container
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .image_caption {
text-outline: 1px solid #231F20;
  }

}
.login_container{
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.login_content_container {
  .email_label{
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold
  }
  h5{
    margin-bottom: 18px;
  }


  .inner_container {
    padding-top: 45px;
    box-sizing: border-box;
    width: 100%;





  }
  .login_form {
    max-width: 650px;
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;

    label {
      color: $BLACK;
      font-size: 13px;
      transform: none;
      margin-bottom: 2px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
    }


    > div {
      margin-bottom: 27px;
    }

    > Button {
      text-align: right;
      width: 100%;
    }

  }

  .button_container {
    display: flex;
    justify-content: flex-end;
    p {
      font-size: 12px;
      a {
        color: $TURQUOISE;
        font-weight: bold;
        text-decoration: none;
      }
    }

  }
  .email_container{
    border-style: solid;
    border-color: $BLUE_GREY;
    border-radius: 2px;
    padding: 0 16px;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
  }

}
.get_new_link_button{
  border-width: 0px;
  padding: 0px;
}

@media screen and (max-width: $TABLET_BREAKPOINT){
  .login_content_container{
    .login_form{
      min-height: unset;
    }
  }
}
