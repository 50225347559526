@import "styles/styles";

.contact_card_container {
  border-top: 0px !important;

  h4{
    text-transform: capitalize;

  }

  > div {
    > div {
      background-image: url(../../../../resources/images/svg/contact-bg.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left;
      background-color: $TURQUOISE;
      border-radius: 10px;
      color: $WHITE;
      text-align: center;
      padding: 82px 18px 45px;
      box-sizing: border-box;
      max-width: 400px;

      .image_container {
        border-radius: 50%;
        width: 200px;
        height: 200px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin: auto;

      }

      a {
        color: $WHITE;
        text-decoration: $WHITE;
        margin-bottom: 9px;
      }

      button {
        margin: 45px auto 0px;
      }

    }

  }

}

@media screen and (max-width: $TABLET_BREAKPOINT) {

  .contact_card_container {
    width: 95%;
    margin: auto;
    height: unset;
    min-height: unset;
    min-width: unset;
    word-break: break-word;
    hyphens: auto;
  }
}
