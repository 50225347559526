@import 'styles/styles';

.plan_card{
    background-image: url(../../../resources/images/svg/plan-card-bg.svg);
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    padding: 36px 18px;
    box-sizing: border-box;
    width: 31%;
    margin: 1%;
    max-width: 304px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    color: $WHITE;
    background-color: $PURPLE;
    border-radius: 10px!important;
    border-top: none!important;

        button{
            margin: 18px auto 0;
            width: 100%;
        }
}
.plan_details {
    flex-grow: 1;
    * {
        padding-bottom: 9px;
        font-size: small;
    }
    p{
        font-weight: bold;
    }
}
.price_container{
    .price {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 18px;
        margin-bottom: 9px;
        span {
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
            padding-right: 9px;
        }
        h3{
            font-size: 45px;
            font-family: 'Poppins', sans-serif;
            margin-top: 9px;
            margin-bottom: 0px;
        }
    }
    .monthly{
        font-weight: bold;
        margin-bottom: 27px;
        font-size: small;
        text-align: center;

    }
}

.label {
    font-weight: bold;
}

@media screen and (max-width: 1100px){
    .plan_card{
        width: 100%;
        margin: 0px auto 27px;
    }
}
