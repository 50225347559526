@import "styles/styles";

.dialog {
  border-top: 8px solid $PURPLE;
  padding: 36px;
  min-width: 440px;
  max-width: 680px;
  width: 100%;
  border-radius: 0 0 10px 10px;



  .title {
    margin-top: 0px;
    margin-bottom: 27px;
    padding-bottom: 9px;
    border-bottom: 1px solid $LIGHT_GREY;
  }

  form {
    label {
      margin-top: 9px;
      color: $BLACK;
      margin-bottom: 3px;
      font-size: 13px;
      transform: none;
    }

    button {
      margin-right: 18px;
      margin-top: 36px;
    }
  }

  .form_error {
    color: red;
    font-size: 9px;
    font-weight: bold;
  }
}

@media screen and (max-width: 740px) {
  :global(.MuiDialog-scrollPaper) {
    align-items: flex-start;
    overflow-y: scroll;
  }


  .dialog {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
    max-height: unset !important;
    min-height: 100%;
    padding: 36px 18px;
  }
}