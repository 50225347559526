@import 'styles/styles';

.dialog_form {

  form {
    > div {
        margin-bottom: 9px;
    }
  }

  label {
    color: $BLACK;
    margin-bottom: 3px;
    font-size: 13px;
    transform: none;
  }
.permission{
  margin-top: 27px;
  background-color: $PURPLE;
  color: $WHITE;
  padding: 12px 9px;
  border-radius: 4px;
p{
  margin-bottom: 4px;
  margin-top: 0px;
  font-weight: bold;
  text-decoration: underline;
}
  span{
    font-size: 13px;
  }
}

  .dialog_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 36px;

    > span {
      width: 18px;
    }
  }

  .form_error {
    color: red;
    font-size: 9px;
    font-weight: bold;
  }
}
