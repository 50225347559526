@import "styles/styles";

.affiliation_container {
  margin-bottom: 45px;
  width: 100%;

  .grey_container {
    background-color: $LIGHT_GREY;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 27px;
    border-radius: 6px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    padding: 18px 0;

    .supplier_count {
      font-size: 48px;
    }

    .supplier_subtitle {
      font-size: 25px;
    }
  }

  .affiliated_suppliers {
    .filter {
      margin-bottom: 18px;

      > div {
        max-width: 200px;
        margin-right: 9px;
        display: inline-block;
      }

      button {
        min-height: 44px;
        margin-right: 9px;
      }
    }

    table {
      width: 100%;
      text-align: left;

      th {
        font-size: 13px;
        border-bottom: 1px solid $LIGHT_GREY;
      }

      tr {
        border-bottom: 1px solid $LIGHT_GREY;
      }

      tbody {
        tr {
          border-bottom: 1px solid $LIGHT_GREY;
          td{
            border-bottom: 1px solid $LIGHT_GREY;

          }
        }
        @media screen and (max-width: 640px){
          font-size: 13px;

          button{
            font-size: 13px;
          }
        }
      }
    }
  }
}
