/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/05/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "colours";

$TABLET_BREAKPOINT: 766px;
$DESKTOP_BREAKPOINT: 990px;

$CONTAINER_WIDTH: 1367px;
$INNER_CONTAINER_WIDTH: 960px;

$PADDING: 9px;
$BORDER_RADIUS: 9px;

@media (min-width: $TABLET_BREAKPOINT) {
    $PADDING: 18px;
}

