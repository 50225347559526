@import 'styles/styles';

.receipt_container{
  p{
    margin: 4px;
    font-family: 'BlissProLight', sans-serif;
  }
  .header_container{
    padding: 45px;
    box-sizing: border-box;
    background-color: #001231;
    color: $WHITE;
    text-align: right;
    img{
      width: 300px;
      margin-bottom: 27px
    }
    .header_details{
      display: flex;
      justify-content: space-between ;
      .customer{
        text-align: left;
      }
      .council{
        text-align: right ;
      }
    }
  }

  .content{
    padding: 18px 45px 45px;
    box-sizing: border-box;

    h2{
      font-family: 'BlissPro', sans-serif;
    }
    .inner_content{
      margin-top: 45px;
      display: flex;
      justify-content: space-between;

      .left{
        width: 30%;
      }
      .right{
        width: 70%;
        hr{
          margin: 27px 0px;
          border: 1px solid black;
        }
        .content_row{
          display: flex;
          justify-content: space-between;
        }
        .grey{
          margin: 27px 0px;
          border: 1px solid #F1F1F2;
        }
      }
      .content_section{
        margin-bottom: 18px;

        .section_label {
          font-weight: bold;
        }

        .reference_number {
          overflow-wrap: break-word;
        }
      }
    }
  }




}