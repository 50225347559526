@import 'styles/styles';

.sidebar_content_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .image_caption {
    //font-weight: bold;
  }

}

.signup_container {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.signup_content_container {


  a {
    text-decoration: none;
    color: $TURQUOISE;
    font-weight: bold;
  }

  h5 {
    margin-bottom: 18px;
  }


  .inner_container {
    padding-top: 45px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .card {
      max-width: 48%;
      display: flex;
      background-color: $LIGHT_GREY;
      border-radius: 0 0 10px 10px;
      border-top: 8px solid $PURPLE;
      flex-direction: column;
      align-items: center;
      padding: 36px;
      text-align: center;
      box-shadow: none;

      > img {
        width: 30%;
        height: auto;
        border-radius: 100%;
      }

      > :not(:last-child) {
        margin-bottom: 18px;
      }

      button {
        width: 100%;
        text-align: center;
        margin-bottom: 9px;
      }

      > a {
        font-size: 14px;
      }

    }


  }


  .button_container {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 12px;

      a {
        color: $TURQUOISE;
        font-weight: bold;
        text-decoration: none;
      }
    }

  }

}

@media screen and (max-width: 1240px) {

  .signup_container {

    .inner_container {
      flex-direction: column !important;
      min-height: unset;


      .card {
        max-width: 100% !important;
        min-width: 100%;
        margin-bottom: 32px;

        > :not(:last-child) {
          margin-bottom: 18px;
        }

        button {
          width: 100%;
          text-align: center;
        }

      }
    }

  }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
  .signup_container {
    .inner_container {
      .card {
        padding: 36px 18px !important;
        h5{
          margin-top: 9px;
          margin-bottom: 0px!important;
        }
      }
    }

    .button_container {
      text-align: center;
      display: block;

      p {
        font-size: 14px;

        a {
          color: $TURQUOISE;
          font-weight: bold;
          text-decoration: none;
        }
      }
    }

  }
}