@import "styles/styles";

.menu_section {
  max-width: 190px;
  width: 100%;
  padding: 0 20px 0 0;

  .menu {
    list-style-type: none;
    border-top: 1px solid $LIGHT_GREY;
    padding-left: 0;
    margin-top: 0;

    > li {
      border-bottom: $LIGHT_GREY 1px solid;
      padding: 18px 0;

      > a {
        text-decoration: none;
        color: $BLACK;
        font-weight: 600;
        font-size: 13px;

        .active {
          font-weight: bold;
          color: $TURQUOISE;
        }
      }
    }
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
  .account {
    .menu_section {
      display: none;
    }
  }
}