@import 'styles/styles';



.padding {
    padding-top: 9px;
    margin-bottom: 12px;
    label{
        color: $BLACK;
        font-size: 13px;
        transform: none;
        margin-bottom: 4px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;

    }
}

.label_tooltip_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
}
.select {
    background: $WHITE url(../../../resources/images/svg/arrow-down-dark.svg) no-repeat ;
    background-position: center right;
    background-origin: content-box;
    min-height: 38px;
    max-height: 46px;
    border-radius: 5px;
    padding: 4px 12px 4px 4px;
    box-sizing: border-box;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    &:focus {
        outline: none;
        box-shadow: $LIGHT_GREY 0 0 0 0.05rem;
        background-image: none;
        -webkit-background-image: none;
        -moz-background-image: none;
    }


    >svg {
        display: none;
    }
    >div {
        background-image: none;
        -webkit-background-image: none;
        -moz-background-image: none;
        background-color: transparent;
    }

}


.select:before{
    display: none;
}

.error {
    color: $ERROR_RED;
    font-size: 0.75rem;
}

@media screen and (max-width: $TABLET_BREAKPOINT){
    .tooltip{
        display: none;
    }
}