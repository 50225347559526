@import 'styles/styles';

.label_tooltip_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    margin-bottom: 0px !important;
    padding-top: 9px;
    box-sizing: border-box;
}

.error {
  color: $ERROR_RED;
  font-size: 0.75rem;
}