@import "styles/styles";

.events {
  background: $BLUE_GREY;
}

.events_content {
  display: inline-flex;
  width: 100%;

  .events_right{
    width: 100%;

    .events_listings{
      margin-bottom: 45px;
      display: inline-flex;
      justify-content: space-between;
      flex-flow: row wrap;
    }

    button{
      width: 100%;
      display: block;
      text-align: center;
    }
  }

}

@media screen and (max-width: $TABLET_BREAKPOINT){
  .events{
    h2 {
      text-align: center;
    }
    .events_content{
      display: block;
      .events_listings{
        max-width: 100%;
        max-height: unset;
        overflow-y: unset;
      }
    }
  }
}
