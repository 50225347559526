@import "styles/styles";

.bannerbar {
    width: 100%;
    background-color: $PURPLE;
    z-index: 1200;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
    padding: 17px 0;
    .inner {
        padding: 0 18px;
        display: flex;
        justify-content: space-between;
        max-width: $INNER_CONTAINER_WIDTH;
        margin: 0 auto;
        align-items: center;
        .text {
            flex-direction: column;
            color: $WHITE;
            font-family: "Poppins", sans-serif;
            .title {
                font-weight: bold;
            }
            .subtitle {
                font-size: 0.8em;
            }
        }
        .buttons {
            .dismiss {
                color: $WHITE;
                margin-right: 18px;
            }
        }
    }
}
.container {
    width: 100%;
    max-width: $CONTAINER_WIDTH - 18 - 18;
    background-color: $PURPLE;
    color: $WHITE;
    z-index: 1200;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.09);
    padding: 16px 32px;
    margin: 24px auto 0;
    display: flex;
    column-gap: 32px;
    justify-content: space-between;
    align-items: first baseline;
    .content {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        > a {
            font-weight: 700;
            color: $WHITE;
        }
    }
    .dismiss {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: $WHITE;
    }
}
