/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/07/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";

.table {
  background-color: transparent;

  :global(.MuiToolbar-root) {
    padding: 9px;

    :global(.MuiTablePagination-select) {
      min-width: auto;
    }
  }

  :global(.MuiSelect-select) {
    min-width: unset;
  }

  table {
    tbody {
      tr {
        td {
          padding: 12px 18px;
        }
      }
    }

    tfoot {
      td {
        border-bottom: none;
      }
    }
  }
}
