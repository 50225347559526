@import "styles/styles";

.page_container {
    max-width: $CONTAINER_WIDTH;
    margin: 0 auto;
}
.container {
    margin: 36px auto;
    display: flex;
    justify-content: space-between;
    .filters_container {
        margin: 0 18px 0 auto;
        padding: 18px 9px;
        flex-basis: 320px;
        min-width: 300px;
        border: 4px solid $GREY_BORDER;
        border-radius: 6px;
        height: fit-content;
        position: sticky;
        top: 36px;
    }
    .cards_container {
        margin: 0 auto;
        width: 100%;
        min-width: 642px;
        background-color: $BACKGROUND_LIGHT_GREY;
        border: 1px solid $GREY_BORDER;
        padding: 16px 9px 16px 17px;
        border-radius: 6px;
        height: fit-content;
    }
}
.result_titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 9px 10px 8px;
    .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .sort_text {
            margin-right: 8px;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
        }
        .save_button {
            margin-right: 18px;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: $TURQUOISE;
            cursor: pointer;
            text-decoration: underline;
        }
        .save_button_disabled {
            cursor: not-allowed;
            color: $GREY;
            opacity: 0.5;
        }
        .save_button_saved {
            display: inline-flex;
            margin-right: 18px;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            > img {
                margin-right: 8px;
                width: 10px;
            }
        }
        .select {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
        }
    }
}
.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}
.label {
    font-size: 0.8em;
    font-weight: bold;
}
.cards {
    display: flex;
    flex-flow: row wrap;
    gap: 8px 13px;
}
.keyword {
    margin: 18px auto;

    .subtitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 9px;
    }
    .keyword_input {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        padding: 15px 18px;
        background-color: $BACKGROUND_LIGHT_GREY;
        border: 1px solid $GREY_BORDER;
        border-radius: 4px;
        :first-child {
            flex-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 225px;
            white-space: nowrap;
        }
        :nth-child(2) {
            margin-left: 9px;
        }
    }
}

.back {
    font-weight: 700;
    color: $TURQUOISE;
    cursor: pointer;
}
