@import "styles/styles";

.policies{
  a{
    color: $TURQUOISE;
    font-weight: bold;
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }

  h1 {
    color: $BLACK;
  }
  h2 {

  }
  h3 {

  }
  h4 {

  }
  h5 {

  }
  h6 {

  }

  blockquote {
    .quote {
      font-weight: normal;
      font-size: 13px;
    }
  }
}