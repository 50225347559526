@import "styles/styles";

.container {
    .header {
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // :first-child {
        //     color: $BLACK;
        //     font: normal normal 600 36px/32px Poppins;
        // }
    }
    .website {
        margin: 16px auto;
        > a {
            color: $TURQUOISE;
            font: normal normal 700 16px/24px Poppins;
            text-decoration: none;
        }
    }
    .main {
        margin-top: 27px;
        font: normal normal 400 16px/27px Poppins;
        .logo {
            width: 210px;
            position: absolute;
        }
        .no_logo {
            width: 210px;
            height: 200px;
            position: absolute;
            background: #f7f7f7 0% 0% no-repeat padding-box;
            border: 1px solid #e6e6e6;
            border-radius: 5px;
            font: normal normal 400 16px/25px Poppins;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .content {
            margin-left: 290px;
            max-width: calc($INNER_CONTAINER_WIDTH - 290px);
            width: 100%;
            .section {
                padding-bottom: 45px;
                .title {
                    color: #00121c;
                    font: normal normal 500 22px/24px Poppins;
                    padding-bottom: 12px;
                    border-bottom: 1px solid $GREY_BORDER;
                }
                .about {
                    white-space: pre-line;
                }
                .vice_title {
                    margin-top: 37px;
                    text-transform: uppercase;
                    color: #808080;
                    font: normal normal 600 14px/24px Poppins;
                }
                .sub_title {
                    margin-top: 20px;
                    font: normal normal 600 16px/27px Poppins;
                    color: #00121c;
                }
                .col_2_container {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    // :nth-child(even) {
                    //     margin-left: auto;
                    // }
                }
                .suffix {
                    color: #0000007e;
                }
                ul {
                    list-style-type: none;
                    padding-left: 0;
                }
                li:before {
                    content: "\2022";
                    margin-right: 9px;
                }
                .contact_container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 18px;
                    border-bottom: 1px solid $GREY_BORDER;
                    > :nth-child(2) {
                        cursor: pointer;
                        color: $TURQUOISE;
                        font: normal normal 600 14px/35px Poppins;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
.socials {
    display: flex;
    justify-content: center;
    column-gap: 18px;
    img {
        width: 25px;
    }
}
.sash {
    padding: 8px 16px;
    width: 113px;
    height: 46px;
    background: #793073;
    border-radius: 8px;
    > span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #ffffff;
    }
}
.back {
    font: normal normal 600 14px/16px Poppins;
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .main {
        .logo {
            display: none;
        }
        .content {
            margin-left: unset !important;
        }
    }
}
