@import "styles/styles";

.page_container {
    background-color: $BLUE_GREY;
    padding: 72px 0;
}
.section_event {
    padding-bottom: 0;
}
.event_detail_container {
    background-color: $WHITE;
    margin: auto;
    max-width: $INNER_CONTAINER_WIDTH;
    border-top: 5px solid $PURPLE;
    font-family: "Poppins", sans-serif;
    .header {
        text-align: left;
        max-width: 700px;
        .group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .buttons {
                .button {
                    margin-right: 9px;
                }
            }
            .socials{
                display: flex;
                align-items: center;
                color: $PLUM_GREY;
                span {
                    font-size: 14px;
                }
                img{
                    display: inline-block;
                    margin-left: 18px;
                    height: 24px;
                    width: auto;
                    object-fit: contain;
                }
                svg{
                    display: inline-block;
                    margin-left: 18px;
                    height: 24px!important;
                    width: auto;
                    object-fit: contain;
                }
            }
        }
    }

    .event_content_container {
      max-width: 700px;
      > div {
        text-align: unset;
  
        h1 {
          color: $BLACK;
        }
  
        a {
          color: $TURQUOISE;
          font-weight: bold;
        }
  
        img {
          width: 100%;
        }
  
        iframe {
          width: 100%;
        }
  
        video {
          width: 100%;
        }
      }
    }

  .event_subheading {
    padding-bottom: 12px;
    border-bottom: 1px solid $LIGHT_GREY;
    margin-bottom: 20px;
  }

  .event_banner_image {
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
      height: 480px;
      object-fit: cover;
      display: block;
    }
  }
  .event_banner_image_single {
    img {
        height: auto;
        object-fit: contain;
    }
  }

  .event_details {
    .event_detail {
      border-bottom: 1px solid $LIGHT_GREY;
      padding: 11px 0;
      font-size: 13px;
      display: flex;

      .title {
        font-weight: bold;
        min-width: 200px;
        display: inline-block;
      }
    }
  }

}
.event_body{
    h1 {
      color:$BLACK;
    }
    a{
      color: $TURQUOISE;
      font-weight: bold;
    }
    img {
      width: 100%;
    }
    iframe{
      width: 100%;
    }
    video{
      width: 100%;
    }
  }


@media screen and (max-width: $TABLET_BREAKPOINT) {
    .step_stone {
        height: 27px;
        background-color: $BLUE_GREY;
    }
  .event_detail_container {
    .group {
        flex-direction: column;
        .buttons {
            width: 100%;
            .button {
                width: 100%;
                margin-bottom: 9px;
            }
        }
    }
    .event_banner_image {
        img {
            height: 240px;
            object-fit: cover;
            display: block;            
        }
    }
  }

}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}