/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";

.loading {
  width: 100%;
}
