@import "styles/styles";

.collapse {
    box-shadow: none;
    margin-bottom: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .accordion_summary {
        padding: 0 0 0 18px;
    }
}
.title {
    // text-transform: lowercase;
    margin-left: -18px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    // &::first-letter {
    //     text-transform: uppercase;
    // }
}
.fully_expanded {
    max-height: unset !important;
    overflow-y: unset !important;
    overflow-x: unset !important;
}
.content {
    padding: 0 0 9px;
    max-height: 270px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    .item_container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: -2px;
        &:hover .hidden_button {
            background-color: $BACKGROUND_LIGHT_GREY;
            display: block;
        }
    }
    .full_input {
        max-width: unset;
        width: 100%;
        margin-bottom: 8px;
        padding-top: 0;
    }
    .select {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
    }
}
.checkbox_container {
    align-items: center;
    .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin: -7px auto auto auto;
    }
    .label_wrap {
        white-space: normal;
    }
}

.checkbox_item_padding {
    padding: 0 5px 7px 0;
}
.hidden_button {
    padding: 9px 18px;
    font-weight: bold;
    font-size: 0.8em;
    cursor: pointer;
    display: none;
    :hover {
        display: block;
    }
}
.tooltip {
    margin-left: 9px;
    position: relative;
    display: flex;
    &:hover p {
        min-width: 270px;
        width: auto;
        height: auto;
        clip: initial;
        opacity: 1;
        visibility: visible;
        font-weight: 400;
        font-size: 12px;
    }
    .icon {
        width: 16px;
    }
    p {
        position: absolute;
        z-index: 999;
        clip: rect(0, 0, 0, 0);
        border: 0;
        background: $BLACK;
        color: $WHITE;
        border-radius: 6px;
        top: -3px;
        transform: translateY(-50%);
        left: 100%;
        padding: 9px 18px;
        overflow-wrap: break-word;
        display: inline-block;
        opacity: 0;
        visibility: hidden;
        // transition: 0.3s;
        &::before {
            content: "";
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            z-index: 1;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 6px solid transparent;
            border-right: 6px solid $BLACK;
        }
    }
}
