@import "styles/styles";

.container {
  display: flex;
  @media screen and (max-width: 640px){
    display: block;
  }

  .mobile_nav{
    border-bottom: 1px solid $PURPLE_GREY;
    display: none;
    color: $PLUM;
    text-align: center;
    font-weight: bold;
    margin-bottom: 18px;

    a{
      border-bottom: 1px solid $PLUM_GREY;
      padding: 0px 9px;
      text-decoration: none;
      margin-right: 9px;
    }

    a:hover{
      color: $PLUM_GREY;
      border-bottom: 1px solid $PLUM_GREY;
    }

    a:active{
      border-bottom: 1px solid $PLUM;
      color: $PLUM;
    }

    @media screen and (max-width: 640px){
      display: block;
    }
  }
  .desktop{
    display: block;
    max-width: 190px;
    width: 100%;
    padding: 0 20px 0 0;
    @media screen and (max-width: 640px){
      display: none;
    }
  }


}
