@import "styles/styles";

.container {
    flex-basis: calc(33% - 9px);
    max-width: calc(33% - 9px);
    background-color: $WHITE;
    display: flex;
    flex-direction: column;
    // align-content: flex-start;
    padding: 12px;
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    min-height: 306px;
    font-size: 13px;
    .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: $TURQUOISE;
    }
    .sub_title {
        margin: 9px auto 9px 0;
        max-width: 92%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
    }
}
.a_not_a {
    font-weight: inherit;
    text-decoration: none;
    color: inherit;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}
.container_buyer {
    min-height: 108px;
    position: relative;
}
.sash_tag {
    position: absolute;
    right: 0;
    top: 0;
    height: 46px;
    width: 46px;
    border-radius: 4px;
    background: linear-gradient(to bottom left, #793073 50%, transparent 50%);
    // margin: -12px -12px auto auto;
    > div {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        transform: rotate(45deg);
        color: #fff;
        padding-left: 14px;
        padding-top: 4px;
    }
}
.pill_container {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    // display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.pill {
    max-width: 45%;
    margin: auto 5px 9px 0;
    padding: 2px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-basis: 45%;
    width: fit-content;
    display: inline-block;
}
.pill_grey {
    background-color: $BACKGROUND_GREY;
    border-radius: 6px;
}
.pill_turquoise {
    background-color: $TURQUOISE_LIGHT;
    color: $WHITE;
    border-radius: 6px;
}
.pill_light {
    background-color: $BACKGROUND_LIGHT_GREY;
    border-radius: 6px;
}
.pill_more {
    color: $TURQUOISE;
}
.pill_bordered {
    margin: 3px auto 6px;
    padding: 5px 0;
    border-top: 1px solid $GREY_BORDER;
    border-bottom: 1px solid $GREY_BORDER;
}
