/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/05/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

$BLACK: #231f20;
$NAVY: #00304b;
$TURQUOISE: #008997;
$PLUM: #4b1642;
$PURPLE: #832676;
$WHITE: #ffffff;
$GREY: #929292;

$ERROR_RED: #f44336;

$BLUE_GREY: #e4e9ec;
$TURQUOISE_GREY: #dbeced;
$PURPLE_GREY: #ece7eb;
$PLUM_GREY: #dfd7dc;
$LIGHT_GREY: #f1f1f2;
$BACKGROUND_GREY: #cae5e7;

$BACKGROUND_OPACITY: #f5f5f5;
$GREY_BORDER: #e6e7e8;

$TURQUOISE_LIGHT: #6ebac2;
$BACKGROUND_LIGHT_GREY: #f9f9f9;
