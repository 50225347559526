@import "styles/styles";

.business_details {
  width: 100%;

  textarea {
    line-height: 1.5;
  }
}
.logo_image{
  >div{
    border-radius: 0px!important;
    background-size: contain!important;
  }
}

.form {
  min-height: 60vh;

  hr {
    border: 1px solid $LIGHT_GREY;
    margin: 18px 0px 36px;
  }

  .full_input {
    max-width: unset;
    width: 100%;
    margin-bottom: 27px;
    position: relative;

    > div {
      width: 100%;
      max-width: unset;
    }
  }

  .health_safety_container {
    > label {
      display: block;
    }

    > div {
      margin-top: 18px;
    }
  }

  .owner_row {
    border-bottom: 1px solid $LIGHT_GREY;
    padding-bottom: 18px;
    box-sizing: border-box;
  }

  .half_input {
    padding-top: 0px;
    margin-bottom: 27px;
    width: 49%;
    display: inline-block;
    vertical-align: top;

    > div {
      width: 100%;
      max-width: unset;
    }
  }

  .form_section {
    margin: 18px 0px 36px;
  }
  .full_text_input{
    margin-bottom: 45px;

    >div{
      >div{
        textarea{
          outline: none;
          max-width: 100%;
          padding: 9px;
          box-sizing: border-box;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          border-radius: 5px;
          min-height: 165px;
          width: 100%;

        }
      }
    }

  }

  .first {
    margin-right: 2%;
  }

  .paragraph_input {
    //margin-bottom: 45px;
    outline: none;
    max-width: 100%;
    padding: 9px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    border: 1px solid #CDCDCD;
    border-radius: 5px;
    min-height: 165px;
    width: 100%;
  }

  .third_input {
    vertical-align: top;
    width: 38%;
    margin-bottom: 0px !important;
    display: inline-block;
  }

  .add_button {
    display: block;
    margin-bottom: 27px;
  }


  .third_input_small {
    vertical-align: top;
    width: 20%;
    margin-bottom: 0px !important;
    display: inline-block;

  }

  .checkbox_container {
    margin-bottom: 36px;

    span {
      font-family: 'Poppins', sans-serif
    }

    label {
      width: 49%;
    }
  }

  label {
    color: $BLACK;
    font-size: 13px;
    transform: none;
    margin-bottom: 2px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }
}

.disclosures {
  margin-top: 27px;
}


.category_container {
  box-shadow: none;
  margin-bottom: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0px 11px;


  .category_item {
    box-shadow: none;

    .category_label {
      label{
        margin-right: 0px;
      }
      span {
        font-weight: bold;

      }

    }

  }
}


.industry_form {
  margin-bottom: 36px;

  .subsub_container {
    display: block;

    > label {
      width: 100%
    }
  }

  .plain_subindustry_row {
    //border: 1px solid black;
    margin-bottom: 0px;
    padding: 12px 18px;
    box-shadow: none;
  }

  .remove_button {
    text-align: right;
    span {
        color: red;
    }
  }
}

.error {
  color: $ERROR_RED;
  font-size: 0.75rem;
  font-weight: bold;
}


@media screen and (max-width: $TABLET_BREAKPOINT) {
  .form {
    min-height: 60vh;

    hr {
      border: 1px solid $LIGHT_GREY;
      margin: 18px 0px 36px;
    }

    .full_input {
      max-width: unset;
      width: 100%;
      margin-bottom: 18px;

    }

    .health_safety_container {
      > label {
        display: block;
      }

      > div {
        margin-top: 18px;
      }
    }

    .half_input {
      padding-top: 0px;
      margin-bottom: 18px;
      width: 100%;
      display: block;

      > div {
        width: 100%;
        max-width: unset;
      }
    }

    .form_section {
      margin: 18px 0px 36px;
    }

    .owner_row {
      padding-bottom: 0px;
    }

    .first {
      margin-right: 2%;
    }

    .third_input {
      vertical-align: top;
      width: 100%;
      margin-bottom: 18px !important;
      display: block;
    }

    .add_button {
      display: block;
      margin-bottom: 9px;
    }

    .third_input_small {
      width: 100%;
      display: block;
      margin-bottom: 18px !important;
    }

    .checkbox_container {
      label {
        width: 100%;
      }
    }
  }
  .disclosures {
    margin-top: 18px;
  }

}

.ownership_notice {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .ownership_main_text {
        font-weight: bold;
    }
    .email {
        color: #CDCDCD;
        text-decoration: underline;
    }
}