@import "styles/styles";

.blog {
  background: $BLUE_GREY;

  h2 {}
}

.blog_content {
  display: flex;
  position: relative;
  width: 100%;

  .blog_filter {
    flex: 1;
  }

  .search_filter_container {
    margin-right: 20px;
  }
  .blog_listings{
    margin-bottom: 45px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .event_listing {

    }
  }
}

.loading_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  min-height: 100%;
  z-index: 1000;

}

@media screen and (max-width: $TABLET_BREAKPOINT){
  .blog{
    h2 {
      text-align: center;
    }
    .blog_content{
      display: block;

      .search_filter_container {
        margin-right: 0;
        margin-bottom: 20px;
      }

      .blog_listings{
        max-width: 100%;
        max-height: unset;
        overflow-y: unset;
      }
    }
  }

}