@import "styles/styles";

.search {
    background-color: $BACKGROUND_LIGHT_GREY;
    border-radius: 13px;
    border: 1px solid $GREY_BORDER;
    padding: 20px 30px;
    .title {
        color: $NAVY;
        display: flex;
        justify-content: space-between;
        align-items: center;
        :first-child {
            font-weight: 500;
            font-size: 25px;
            line-height: 38px;
        }
        :nth-child(2) {
            font-size: 12px;
            font-weight: 700;
            color: $TURQUOISE;
            cursor: pointer;
        }
    }
    .input_container {
        margin-top: 6px;
    }
    .input {
        border: 1px solid $TURQUOISE;
        border-radius: 8px;
        padding: 4px 12px 4px 24px;
        font-weight: 400;
        font-size: 12px;
        color: #00304b;
    }
    .popover {
        margin-top: -21px;
        overflow-y: auto;
        padding: 23px 0;
        border-radius: 9px;
        border: solid 1px $TURQUOISE;
        box-shadow: $LIGHT_GREY 0 0 0 0.1rem;
        z-index: 999;
        position: absolute;
        background-color: $WHITE;
    }
}
.tips {
    margin: 16px auto auto 24px;
    font-weight: 400;
    font-size: 12px;
    color: #00304b;
    :nth-child(2) {
        font-weight: bold;
        color: $TURQUOISE;
        cursor: pointer;
    }
}
.no_result {
    padding-left: 24px;
    font-weight: 400;
    font-size: 12px;
}
.menu {
    padding-left: 24px;
    color: #00304b;
    .item {
        font-weight: 400;
        font-size: 12px;
        width: 100%;
        display: flex;
        > * {
            flex-basis: 50%;
        }
    }
}
.frequent_search {
    padding-left: 24px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #00304b;
    display: flex;
    .items {
        display: flex;
        > * {
            color: $TURQUOISE;
            margin-left: 18px;
            cursor: pointer;
        }
    }
}
