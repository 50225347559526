@import "styles/styles";

.container {
    position: relative;
    width: 100%;
    padding: 62px 18px;
    min-height: 90vh;
}

.inner {
    width: 100%;
    max-width: $INNER_CONTAINER_WIDTH;
    margin: 0 auto;
    position: relative;
}

.back {
    font-weight: bold;
    font-size: 0.8em;
    cursor: pointer;
    color: $TURQUOISE;
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .container {
        padding: 45px 18px;
    }
}
