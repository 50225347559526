@import "styles/styles";

.button {
    min-height: 48px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    padding: 0 27px;
    box-sizing: border-box;
    min-width: unset;
    font-size: 14px;
    text-align: center;
    transition: ease-in-out 125ms;
    border-radius: 5px;
    border: 2px solid transparent;
    background-color: transparent;
    color: $WHITE;
    justify-content: center;
    line-height: 16px;
    letter-spacing: 0;
    width: max-content;

    .children {
        flex: 1;
    }

    .spinner {
        ellipse {
            stroke: #fff;
        }
    }
    &:hover {
        background-color: $TURQUOISE;
        opacity: 0.6;
        transition: ease-in-out 125ms;
    }
}

.default {
    background-color: $TURQUOISE;
    border: 2px solid $TURQUOISE;
}

.plain {
    background-color: transparent;
    color: $BLACK;
    box-shadow: none;
    text-align: left;
    padding: 0px;

    &:hover {
        color: $TURQUOISE;
        background-color: transparent;
        text-decoration: underline;
    }
}
.plainLink {
    background-color: transparent;
    color: $TURQUOISE;
    box-shadow: none;
    text-align: left;
    padding: 0px;
    border: none;

    &:hover {
        color: $TURQUOISE;
        background-color: transparent;
        text-decoration: underline;
    }
}
.link {
    text-decoration: none;
}

.white {
    background-color: white;
    box-shadow: none;

    &:hover {
        background-color: transparent;
        color: white;
    }
}

.dark {
    background-color: $TURQUOISE;
    box-shadow: none;

    &:hover {
        background-color: transparent;
        color: white;
    }
}

.border {
    border: 2px solid $BLACK;
    color: $BLACK;
    &:hover {
        border: 2px solid $BLUE_GREY;
        background-color: transparent;
        color: $BLUE_GREY;
    }
}
.borderwhite {
    border: 2px solid $WHITE;
    color: $WHITE;
    background-color: transparent;
    &:hover {
        opacity: 0.6;
        background-color: transparent;
    }
}
.borderTurquoise {
    border: 2px solid $TURQUOISE;
    color: $TURQUOISE;
    background-color: transparent;
    &:hover {
        opacity: 0.6;
        border: 2px solid $TURQUOISE;
        color: $TURQUOISE;
        background-color: transparent;
    }
}
.borderdark {
    border: 2px solid $TURQUOISE;
    color: $TURQUOISE;
    background-color: transparent;
    &:hover {
        opacity: 0.6;
        background-color: transparent;
    }
}

.center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.large {
    min-height: 54px;
    font-size: 22px;
}

.regular {
    font-weight: normal;
}

.long {
    padding: 0 36px;
}

.selected {
    background-color: white;
    color: $PURPLE;
    &:hover {
        background-color: white;
        color: $PURPLE;
        opacity: 1;
    }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .button {
        font-size: 14px;
    }
}
