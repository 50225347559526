.iframe {
  margin: 18px 0px;
  iframe {
    width: 100%;
    height: 380px;
  }
}

.sound_cloud {
  iframe {
    height: 126px;
  }
}

.video {

}

.spotify {
  iframe {
    height: 80px;
  }
}