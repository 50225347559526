@import 'styles/styles';
.users {
    width:100%;
    padding-left:20px;
    h5 {
        border-bottom: 1px solid $LIGHT_GREY;
        padding-bottom: 3px;
        margin-bottom: 29px;
    }
}
.links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
