@import "styles/styles";

.image {
    height: 190px;
    width: 100%;
    border-radius: 5px;

}
.subtitle{
    display: block;
    margin: 9px 0px;
    font-size: 11px;
    text-align: center;
}
.featured_subtitle{
    text-align: left;
}
.post_content{
    h5{
        text-align: center;
        margin-top: 9px;
        margin-bottom: 0px;
    }
    p{
        margin-top: 0px;
    }
}
.container {
    background-color: $WHITE;
    border-top: 6px solid $PURPLE;
    padding: 18px;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 0 18px;
    width:100%;
    flex-basis: 49%;
    min-height: 360px;
}
.featured_container {
    min-height: 243px;
    flex-basis: 100%;
    display: flex;
}
.featured_image {
    flex: 2;
}
.featured_content {
    margin-left: 27px;
    flex: 3;
    h5 {
        text-align: left;
    }
    p {
        margin-top: 6px;
    }
}

.button {
    font-size: 13px;
    margin-top: 18px;
    font-weight: bold;
    color: $TURQUOISE;
}
