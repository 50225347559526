

@import "styles/styles";

.navbar {
    width: 100%;
    background-color: $NAVY;
    z-index: 1200;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .09);
    padding: 17px 0 17px;

    .inner {
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 18px;

        max-width: $CONTAINER_WIDTH;

        .logo {
            padding-top: 9px;
            a{
                background-color: transparent;
            }
          img{
              width: 115px;
          }
        }

        .nav {
            flex: 1;
            align-items: center;
            display: flex;
            justify-content: flex-end;

            > :not(:last-child) {
                margin-right: 9px;
                text-decoration: none;
            }
        }

        .search_input{
            margin-left: 18px;
            max-width: 330px;
            width: 100%;


            input{
                background-color: #1A4A60;
                border: none;
                color: $WHITE;
                min-height: unset;
                box-sizing: border-box;
                font-size: 12px;
                margin-bottom: 0;

                &:focus {
                    border:none;
                }
            }
        }
    }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .toolbar{
        padding: 14px 0 13px;

        .inner{
            .logo{
                img{
                    max-width: 92px;
                }
            }
            .search_input{
                display: none!important;
            }
        }

    }
}
