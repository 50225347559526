
@import "styles/styles";




.resource_card{
  width: calc(100%);
  padding: 32px 0;
  box-sizing: border-box;
  border-bottom: 1px solid $LIGHT_GREY;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .left {
    max-width: 20%;
    width: 100%;

    >img {
      border-radius: 5px;
      width: 100%;
    }

  }

  .right {
    max-width: 78%;
    width: 100%;
    padding: 16px;


    >h5{
      margin: 0 0 5px;
    }

    >Button {
      cursor: pointer;
    }


  }
  .resource_right{
    max-width: 100%;
    width: 100%;
    padding: 16px;


    >h5{
      margin: 0 0 5px;
    }

    >Button {
      cursor: pointer;
    }

  }

}

@media screen and (max-width: $TABLET_BREAKPOINT){
  .resource_card{
    width: 100%;
    margin: 9px 0px 12px;
    padding: 4px 0px 9px;
    flex-direction: column;
    align-content: center;

    .left {
      display: none;
      //max-width: 100%;
      //padding: 16px 0;
      //
      //>img {
      //  width: 100%;
      //}

    }
    .resource_right{
      max-width: 100%;

      >h5{
        margin: 0 0 5px;
      }

      >Button {
        width: 100%;
      }
    }

    .right {
      max-width: 100%;

      >h5{
        margin: 0 0 5px;
      }

      >Button {
        width: 100%;
      }

    }

  }
}
