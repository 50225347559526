@import 'styles/styles';

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 64px;
    .content {
        display: flex;
        flex-direction: column;
        h3 {
            margin: 0 0 6px;
        }
        .subtitle {
            font-size: 13px;
            color: $NAVY;
        }

    }
    a{
        text-decoration: none;
    }
    button {
        max-height: 48px;
        text-decoration: none;
    }
}


@media screen and (max-width: $TABLET_BREAKPOINT){
    .header {
        display: block;
        margin-bottom: 55px;
        .content {
            margin-bottom: 18px;
        }
    }
}