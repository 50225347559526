@import "styles/styles";

.blog_card {
  width: 48%;
  box-sizing: border-box;
  padding: 24px;
  background: #FFFFFF;
  border-radius: 0 0 9px 9px;
  border-top: #832676 9px solid;
  text-align: left;
  margin: 0 1% 24px;
  cursor: pointer;

  .image {
    height: 190px;
    width: 100%;
    border-radius: 5px;
  }

  h5 {
    margin: 9px 0 9px;
  }
  .card_date{
    display: block;
    margin: 9px 0;
    font-size: 11px;
  }
  button {
    min-height: unset;
    height: unset;
    padding: 0px;
    
  }

}

.blog_card:hover {
  box-shadow: 1px 1px 6px rgba(0,0,0,0.1);
}

@media screen and (max-width: 940px){
  .blog_card {
    min-width: 90% !important;
    width: 90% !important;
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT){

  .blog_card {
    min-width: 100% !important;
    width: 100% !important;
  }
}