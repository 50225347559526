@import "styles/styles";

.my_profile {
  width:100%;
  padding-left:20px;
  h5 {
    border-bottom: 1px solid $LIGHT_GREY;
    padding-bottom: 3px;
    margin-bottom: 29px;
  }
  .profile_details {
    display: flex;
    .avatar {
      width: 30%;
      .avatar_image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 180px;
        height: 180px;
        border-radius: 50%;
      }

    }
    .text {
      width: 70%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 18px;
      >div{
        width: 48%;
        margin-bottom: 27px;
      }

      label{
        transform: unset;
        font-size: 13px;
        color: $BLACK;
        margin-bottom: 3px;

      }

      button{
        display: block;
        width: 100%;
      }

      .disabled_input{
        label{
          margin-bottom: 3px;
          display: block;
          font-size: 13px;
          font-family: Poppins, sans-serif;
          font-weight: bold;
          color: $BLACK
        }
      }

    }
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT){
  .my_profile {
    .profile_details {
      display: block;
      .avatar {
        width: 100%;
        margin-left:0;
      }
      .text {
        width: 100%;
        display: block;
        >div{
          width: 100%;
        }
        .detail_block {
          width: 100%;
        }
      }
    }
  }
}