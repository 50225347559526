@import "styles/styles";

.blog_detail_container {

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
      max-width: 20%;
      width: 100%;
      text-align: center;
      text-align: left;

      a {
        color: $TURQUOISE;
        font-weight: bold;
        text-decoration: none;
        font-size: 13px;
        display: block;
        padding: 18px 0;
      }
    }

    .right {
      max-width: 78%;
      width: 100%;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;

        .header_content {
          display: flex;
          flex-direction: column;

          h3 {
            margin: 0 0 6px;
          }

        }
      }

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      h1 {
        color: $BLACK;
      }
      h2 {

      }
      h3 {

      }
      h4 {

      }
      h5 {

      }
      h6 {

      }

      blockquote {
        .quote {
          font-weight: normal;
          font-size: 13px;
        }
      }
      a{
        color: $TURQUOISE;
        text-decoration: none;
        font-weight: bold;
      }


      //.blog_details {
      //  .blog_detail {
      //    border-bottom: 1px solid $LIGHT_GREY;
      //    padding: 11px 0;
      //    font-size: 13px;
      //    .title {
      //      font-weight: bold;
      //      min-width: 200px;
      //      display: inline-block;
      //
      //    }
      //  }
      //}

      .footer{
        margin-bottom: 36px;

        hr{
          opacity: 0.6;
        }

        .social{
          margin-right: 27px;
        }
      }
    }
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT){

  .blog_detail_container {

    > div {
      display: flex;
      flex-direction: column;

      .left {
        max-width: 100%;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
      }

      .right {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}