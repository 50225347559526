@import "styles/styles";


.resource_detail_page {

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .left {
      max-width: 20%;
      width: 100%;
      text-align: center;

      a {
        color: $TURQUOISE;
        font-weight: bold;
        text-decoration: none;
        font-size: 13px;
      }
    }

    .right {
      max-width: 78%;
      width: 100%;

      .resource_header {

        margin-bottom: 81px;

        h3 {
          margin-bottom: 10px;
          margin-top: 0;
        }

      }

      .video{
        overflow: hidden;
        // 16:9 aspect ratio
        padding-top: 56.25%;
        position: relative;

        >iframe {
          border: 0;
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      .footer{
        margin-bottom: 36px;

        hr{
          opacity: 0.6;
        }
        .social{
          margin-right: 27px;
        }
      }

    }
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
  .resource_detail_page {

    > div {
      display: block;

      .left {
        max-width: 100%;
      }

      .right {
        max-width: 100%;

        .resource_header {
          text-align: center;
          margin-top: 45px;
          margin-bottom: 0px;
        }

        .video {
          margin-top: 32px;
        }

      }
    }
  }


}