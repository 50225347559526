@import 'styles/styles';

.padding {
    padding-top: 9px;

    .menu_dropdown_container{
        position: relative;
        z-index: 1;
        .menu_dropdown{
            top: -27px;
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 100;
            width:100%;
            background-color: white;
            cursor: pointer;
            .menu_item{
                padding: 8px 12px;
                border-width: 1px;
                border-left-style: solid;
                border-right-style: solid;
                border-color: #F1F1F2;
                z-index: 1000;
                width: 100%;
            }:hover{
                 background-color:#F1F1F2 ;

             }:last-child{

                  border-bottom-style: solid;
                  border-color: #F1F1F2;

              }

        }
    }
}
.input {
    background-color: $WHITE;

    margin-bottom: 12px;
    min-height: 38px;
    border-radius: 5px;
    border: solid $PLUM_GREY 1px;
    padding: 4px 12px 4px;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    &:focus {
        outline: none;
        box-shadow: $LIGHT_GREY 0 0 0 0.05rem;
    }
}

.formik_input {
    width: 100%;
}

input::-webkit-input-placeholder {
    line-height:normal!important;
}

.label_tooltip_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;

}
.label{
    font-size: 13px;
    transform: none;
    margin-bottom: 4px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: $BLACK;
}
.noPadding {
    padding: 0
}

.error {
    color: $ERROR_RED;
    font-size: 0.75rem;
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    .label_tooltip_container{
        img{
            display: none;
        }
    }
}