@import "styles/styles";

.outer_container {
    padding: 51px 18px;
}
.container {
    margin: 0 auto;
    max-width: $CONTAINER_WIDTH;
    .subtitle {
        display: flex;
        align-items: center;
        // padding: 16px 24px;
        // background: #cae5e7;
        // border-radius: 5px;
        width: fit-content;
        > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #231f20;
        }
        > img {
            margin-right: 8px;
            height: 20px;
        }
    }
}

.section {
    margin-top: 27px;
    background-color: $BACKGROUND_LIGHT_GREY;
    border-radius: 13px;
    border: 1px solid $GREY_BORDER;
    // box-shadow: $LIGHT_GREY 0 0 0 0.05rem;
    padding: 20px 30px;
    .title {
        color: $NAVY;
        font-weight: 500;
        font-size: 25px;
        margin-bottom: 15px;
    }
}
.search_container {
    margin: 36px 0;
    .categories {
        margin-top: 27px;
        .others {
            .collapse {
                background-color: $BACKGROUND_LIGHT_GREY;
                box-shadow: none;
                margin-bottom: 0px !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                .content {
                    padding: unset;
                }
            }
        }
    }
}
.main_cat {
    color: $NAVY;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    margin: 4px auto 4px 0;
}
.buyer_main_cat {
    color: $NAVY;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin: 12px 0;
    border-bottom: 2px solid $GREY_BORDER;
    padding: 5px 0;
}
.subs {
    display: flex;
    cursor: pointer;
    flex-wrap: wrap;
    .sub {
        // margin: 9px 9px 9px auto;
        flex: 0 1 230px;
        display: flex;
        flex-direction: column;
        padding-right: 20px;
        > label {
            color: $NAVY;
            font-weight: bold;
            margin-bottom: 9px;
            cursor: pointer;
        }
        > span {
            margin-bottom: 6px;
        }
    }
}

.tips {
    margin: 36px auto 36px 18px;
    :nth-child(2) {
        font-weight: bold;
    }
    :nth-child(4) {
        text-decoration: underline;
        cursor: pointer;
    }
}
.menu {
    .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        :nth-child(2) {
            color: grey;
        }
    }
}
.saved_content {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .pill {
        font-size: 12px;
        font-weight: 400;
        min-width: 180px;
        max-width: calc(20% - 6.4px);
        flex-basis: calc(20% - 6.4px);
        background-color: $WHITE;
        display: flex;
        align-items: center;
        padding: 16px 20px;
        border: 1px solid $GREY_BORDER;
        justify-content: space-between;
        border-radius: 6px;
        .fav_label {
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 135px;
        }
        .delete {
            margin-left: 18px;
            cursor: pointer;
        }
    }
}
.loading {
    cursor: not-allowed;
    color: $LIGHT_GREY;
}

.learn_more {
    font-family: "Poppins";
    font-style: normal;
    padding: 16px 20px;
    border-top: none;
    border: 1px solid #008997;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    .title {
        font-weight: 500;
        font-size: 25px;
        line-height: 38px;
        color: $TURQUOISE;
    }
    .content {
        margin-top: 16px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        > ol {
            margin: 16px 0 0 0;
            padding-left: 20px;
        }
    }
    .footer {
        margin-top: 32px;
        text-align: right;
    }
}
