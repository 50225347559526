@import 'styles/styles';

.sidebar_content_container
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .image_caption {
    //font-weight: bold;
  }

}
.login_container{
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.login_content_container {

  a{
    text-decoration: none;
    color: $TURQUOISE;
    font-weight: bold;
  }

  h5{
    margin-bottom: 18px;
  }


    .inner_container {
      padding-top: 45px;
      box-sizing: border-box;
      width: 100%;





    }
  .login_form {
    max-width: 650px;
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;

    label {
      color: $BLACK;
      font-size: 13px;
      transform: none;
      margin-bottom: 2px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
    }


    > div {
      margin-bottom: 27px;
    }
    .forgot_container{
     text-align: right;
      a{
        text-decoration: none;
      }
    }

    > Button {
      text-align: right;
      width: 100%;
    }

  }

  .button_container {
    display: flex;
    justify-content: space-between;

    p {

      a {
        color: $TURQUOISE;
        font-weight: bold;
        text-decoration: none;
      }
    }

  }

  }
@media screen and (max-width: $TABLET_BREAKPOINT){
  .login_content_container {
    .inner_container {
      padding-top: 18px;
      box-sizing: border-box;
      width: 100%;

    }

    .login_form {
      min-height: unset;
      margin-bottom: 36px;

      > div {
        margin-bottom: 27px;
      }

      a {
        text-align: center;
        text-decoration: none;
      }

      > Button {
        text-align: right;
        width: 100%;
      }
      .forgot_container{
        text-align: center;
        a{
          text-decoration: none;
        }
      }

    }

    .button_container {
      display: block;
      text-align: center;
      button{
        width: 100%;
      }

    }

  }
}
