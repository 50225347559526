

@import "styles/styles";

.section {
    position: relative;
    width: 100%;
    background-color: transparent;
    margin: 36px 0;
    padding-bottom: 36px;
}

.inner {
    width: 100%;
    max-width: $INNER_CONTAINER_WIDTH;
    margin: 0 auto;
    position: relative;

}

.section_title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 9px;
}

@media screen and (max-width: $TABLET_BREAKPOINT){
    .section{
        padding: 12px 0px;
        margin: 18px 0;

    }

}
