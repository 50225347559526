/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 16/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";


.footer {
  background-color: $NAVY;
  padding: 50px 18px 30px;


  .footer_container {
    margin: 0 auto;
    max-width: 960px;
    display: flex;
  }

  .footer_left {
    color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 2;

    .footer_left_left {
      width: 50%;

      > a {
        display: block;
        color: white;
        margin-bottom: 9px;
        text-underline: none;
        text-decoration: none;
        font-size: 13px;
        font-weight: 500;
      }
    }


    .footer_left_right {
      width: 50%;
      padding: 0px 18px;

      > a {
        color: white;
        margin-bottom: 9px;
        text-underline: none;
        text-decoration: none;
        font-size: 13px;
        font-weight: 500;
      }


    }
  }

  .footer_right {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .socials{
      display: block;
      margin-top: 18px;
      img{
        display: inline-block;
        margin-right: 18px;
        height: 24px!important;
        width: auto;
      }
      svg{
        display: inline-block;
        margin-right: 18px;
        height: 24px!important;
        width: auto;
      }
    }
  }
}


.footer_bar_container {
  background-color: $BLACK;
  padding: 0;

  .footer_bar {
    max-width: 960px;
    margin: 0 auto;
    padding: 9px 0;
    color: white;
    display: flex;
    flex-direction: row;
    font-size: 13px;

    .footer_bar_left {
      flex: 1;

      a {
        color: $WHITE;
      }
    }

    .footer_bar_right {
      font-weight: 600;

      a {
        text-decoration: none;
        color: $WHITE;
      }

      img {
        margin-left: 9px;
      }
    }
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
  .footer {
    .footer_container {
      display: block;
      width: 100%;

      .footer_left {
        text-align: center;
        display: block;

        .footer_left_left {
          width: 100%;
        }

        .footer_left_right {
          padding: 0px;
          width: 100%;

        }
      }

      .footer_right {
        text-align: center;
        display: block;
        .socials{
          svg{
            margin: 4px 9px;
          }
        }

      }
    }
  }

  .footer_bar_container {
    .footer_bar {
      display: block;
      text-align: center;
      padding: 9px;

      .footer_bar_right {
        margin-top: 6px;
      }
    }
  }
}


