@import "styles/styles";

:global(.StripeElement) {
  width: 100%;
  min-width: 100px;
  padding: 11px 9px 11px;
  border: 1px solid $PLUM_GREY;
  border-radius: 5px;
}

.stripe_input_container {
  padding: 9px 0 0;
}

.label{
  font-size: 13px;
  transform: none;
  margin-bottom: 4px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: $BLACK;
}