@import "styles/styles";

.check_box_container {
    display: flex;
    margin: 0;
    > span {
        user-select: none;
        > span {
            color: $GREY;
        }
    }
    .check_box {
        svg {
            color: $TURQUOISE;
        }
    }

    .unchecked {
        svg {
            color: #e6e7e8;
        }
    }
}
