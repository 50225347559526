@import 'styles/styles';

.sidebar_content_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .image_caption {
    text-outline: 1px solid #231F20;
  }

}

.password_reset_container {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.password_reset_content_container {

  h5{
    margin-bottom: 18px;
  }


  .inner_container {
    padding-top: 45px;
    box-sizing: border-box;
    width: 100%;





  }
  .password_reset_form {
    max-width: 650px;
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;

    label {
      color: $BLACK;
      font-size: 13px;
      transform: none;
      margin-bottom: 2px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
    }


    > div {
      margin-bottom: 27px;
    }

    > Button {
      text-align: right;
      width: 100%;
    }
    a {
      color: $TURQUOISE;
      font-weight: bold;
      text-decoration: none;
      font-size: 13px;
    }

  }

  .button_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {

      a {
        color: $TURQUOISE;
        font-weight: bold;
        text-decoration: none;
      }
    }

  }

}

.dialog_button_wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 27px;

  > Button {
    margin-right: 18px;
  }
}
