@import "styles/styles";

.header {
  border-bottom: 1px solid $LIGHT_GREY;

  h5 {
    margin-bottom: 9px;
  }
  p {
    margin: 0 0 4px;
  }
}
