@import "styles/styles";

.account {
    //background-color: $LIGHT_GREY;
    .container {
        display: flex;
    }

    .delete_account {
        margin-top: 45px;
        padding: 18px 0px;
        box-sizing: border-box;
        text-align: right;
        border-top: 1px solid $LIGHT_GREY;
    }

    .account_status_container {
        padding: 9px;
        box-sizing: border-box;

        h5 {
            margin: 0 0 9px;
        }

        .review_message {
            margin: 9px 0px 18px;
            font-weight: bold;
        }
    }
}
.mail {
    color: white;
    text-decoration: underline;
}
