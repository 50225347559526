@import "styles/styles";

@font-face {
    font-family: "BlissPro";
    src: url("../resources/fonts/BlissPro.otf");
}

@font-face {
    font-family: "BlissProLight";
    src: url("../resources/fonts/BlissLight.ttf");
}

* {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    position: relative;
    text-rendering: optimizeLegibility;
}

body > div {
    width: 100%;
    //height: 100%;
    position: relative;
    text-rendering: optimizeLegibility;
}

body {
    font-size: 14px;
    background-color: $WHITE;
    font-family: "Poppins", sans-serif;
    overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    //margin: 27px 0;
}

h1 {
    font-weight: bold;
    font-size: 49px;
    line-height: 56px;
    color: $WHITE;
    font-family: "Libre Baskerville", serif;
}

h2 {
    font-weight: bold;
    font-size: 39px;
    line-height: 48px;
    margin-bottom: 9px;
    font-family: "Libre Baskerville", serif;
}

h3 {
    font-weight: bold;
    font-family: "Libre Baskerville", serif;
    font-size: 31px;
    line-height: 32px;
}

h4 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 25px;
    line-height: 24px;
}

h5 {
    font-family: "Libre Baskerville", serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

h6 {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 24px;
}

p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Poppins", sans-serif;
}

blockquote {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    margin: 31px 0;
}

a {
    cursor: pointer;
}

input {
    line-height: 1px !important;
}

ul {
    padding-top: 3px;
    padding-left: 21px;
}
li {
    margin-bottom: 3px;
}

:global(.error) {
    color: red;
    font-size: 9px;
    font-weight: bold;
}

:global(.infinite-scroll-component) {
    overflow: hidden !important;
    width: 100% !important;
}

:global(.infinite-scroll-component__outerdiv) {
    width: 100% !important;
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
    h1 {
        font-weight: bold;
        font-size: 34px;
        line-height: 40px;
    }

    h2 {
        font-weight: bold;
        font-size: 28px;
        line-height: 32px;
    }

    h3 {
        font-weight: bold;
        font-family: "Libre Baskerville", serif;
        font-size: 22px;
        line-height: 24px;
    }

    h4 {
        font-family: "Poppins", sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 19px;
    }

    h5 {
        font-family: "Libre Baskerville", serif;
        font-weight: bold;
        font-size: 17px;
        line-height: 19px;
        margin-bottom: 16px;
    }

    h6 {
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        line-height: 24px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        font-family: "Poppins", sans-serif;
    }

    blockquote {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        font-family: "Poppins", sans-serif;
    }
}
