@import "styles/styles";

.contact_card_container {
  > div {
    > div {
      background-color: $WHITE;
      border-radius: 0 0 10px 10px;
      border-top: 8px solid $PURPLE;
      text-align: center;
      padding:  45px 18px 45px;
      box-sizing: border-box;
      h5{
        margin: 18px;
      }

      button{
        margin: 18px auto 0px;
      }

    }

  }

}