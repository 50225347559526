/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";


.page_container{
  background-color: $WHITE;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  .content{
    text-align: center;
    margin: 30% auto;

    img{
      width: 80px;
      height: 80px;
    }
    .loading {
      height: 100%;
      width: 100%;
    }
  }
}


