/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 14/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

@import "styles/styles";

.snackbar {
  margin-bottom: 18px;
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.10);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.10);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.10);
  .snackbar_content {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    border-radius: 9px;
    //padding: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: unset;

    .snackbar_content_message {
      flex: 1;
      display: flex;
      align-items: center;

      > span {
        margin-left: 9px;
      }
    }
  }

  .success {
    background-color: $TURQUOISE;
  }

  .error {
    background-color: $PURPLE;
  }

  .warning {
    background-color: $PLUM;
  }
}

