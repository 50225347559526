@import "styles/styles";

.onboarding_container{
  max-height: 100vh;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.onboarding_form {
  width: 100%;
}

.intro {
  a {
    text-decoration: none;
    color: $TURQUOISE;
    font-weight: bold;
  }

}

.breadcrumbs {
  color: $WHITE;

  .step_selected {
    color: $TURQUOISE;
    font-weight: bold;
  }

  .step_completed {
    color: $TURQUOISE;
    cursor: pointer;
    font-weight: bold;
  }

  li::before {
    height: 10px;
    width: 10px;
  }
}

.action_buttons {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;

}

.buyer_client_container {
  h5{
    margin-bottom: 18px;
  }

  .member_type_container {
    padding-top: 45px;
    box-sizing: border-box;

    .member_type_card {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      position: relative;
      background-position: left;
      background-image: url(../../../resources/images/svg/membership_type_bg.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-color: $PURPLE;
      border-radius: 10px;
      color: $WHITE;
      max-width: 540px;
      width: 100%;
      min-height: 130px;
      padding: 9px 15% 36px;
      margin-bottom: 16px;

      span{
        font-size: 14px;
        opacity: 0.5;
        font-weight: bold;
      }
      h4{
        margin-bottom: 5px;
      }
    }

    .member_type_card_selected {
      background-color: rgba(130, 38, 118, 0.8);
    }
  }

  .error {
    color: $ERROR_RED;
    font-size: 0.75rem;
  }
}

.supplier_container{
  .intro_sub{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  h5{
    margin-bottom: 18px;
  }
  >p{
    // max-width: 550px;
    margin-bottom: 45px;
  }
  .faqs {
    .faq_content {
      margin-bottom: 9px;
      box-shadow: none;
      border-radius: 5px;
    }
    .faq_content:before{
      background-color: transparent!important;
      top:0px!important;
    }

    .faq_question {
      background-color: $LIGHT_GREY;
      border-radius: 5px;

      .faq_question_text {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: $BLACK;
      }
      img{
        width: 14px;
        height: 13px;
      }
    }
    .faq_question[aria-expanded="true"]{
      background-color: $PURPLE;
      border-radius: 5px 5px 0px 0px;

      .faq_question_text{
        color: $WHITE!important;
      }
    }

    .faq_answer {
      border-radius: 0px 0px 5px 5px;
      padding: 18px 27px 27px;
      background-color: $PURPLE_GREY;

      ul{
        margin: 0px;
      }
    }
  }
  .single_button_container{
    text-align: right;
    margin: 45px 0px;
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT){
  .onboarding_container{
    max-height: unset;
  }
  .supplier_container{
    .single_button_container{
      margin-bottom: 45px;
      margin-top: 18px;

      >button{
        width: 100%;
        text-align: center;
      }
    }
  }
  .action_buttons{flex-direction: column-reverse;
    margin-bottom: 45px;
    margin-top: 18px;
    > button{
      width: 100%;
      text-align: center;
    }
  }
}
