@import "styles/styles";

.contact_card_container {
  > div {
    > div {
      background-color: $WHITE;
      border-radius: 0 0 10px 10px;
      border-top: 8px solid $PURPLE;
      padding:  45px 18px 45px;
      box-sizing: border-box;
      h5{
        margin: 18px;
      }
      .button_container{
        button{
          display: inline-block;
          margin: 18px 18px 0px 0px;
        }
      }


    }

  }

}
