@import "styles/styles";

.billing {
  width: 100%;
  max-width: 80%;
  padding-left: 20px;

  h5 {
    border-bottom: 1px solid $LIGHT_GREY;
    padding-bottom: 3px;
    margin-bottom: 29px;
  }

  .show_plans_button {
    padding: 0;
    border: none;
    letter-spacing: 0;
    font-size: 13px;

    img {
      margin-left: 9px;
    }
  }
}

.credit_card_row {
  border-bottom: $LIGHT_GREY solid 1px;
  padding-bottom: 22px;
  margin-bottom: 48px;
}

.items {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .item {
    display: flex;
    flex-direction: column;

    span {
      display: block;
    }

    .label {
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 3px;
    }
  }
}

.view_plans {
  margin-top: 18px;

  > label {
    font-weight: bold;
  }

  .plans {
    margin-bottom: 27px;
    margin-top: 18px;
    display: flex;
    flex-wrap: wrap;

    button:disabled {
      color: $LIGHT_GREY;
      opacity: .6;
    }

  }
}

.plan_btn {
  margin-bottom: 32px;
}

.card_btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 98px;

  > div {
    width: 9px;
  }
}

.plan_dialog {
  background-color: transparent;
  border:none;
  margin: auto;
  box-shadow: none;
  min-width: unset;
  max-width: 500px;
  justify-content: center;

  .plan_card{
    max-width: 100%;
  }


  .update_plan_btns {
    text-align: center;
    margin-top: 18px;
    button{
      text-align: center;
      margin-bottom: 18px;
    }
  }
}
.download_link{
  font-weight: bold;
  color: $TURQUOISE;
  font-size: 14px;
}

.card_default {
  font-style: italic;
  color: $TURQUOISE;
  font-size: 9px;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.card_btn {
  margin-top: 18px;
}

.cards{
  table{
    th{
      font-weight: bold;
      font-size: 13px;
      margin-bottom: 3px;
      font-family: 'Poppins', sans-serif;
      padding: 16px 4px;
    }
  }
}

.card_form {
  .card_form_footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > span {
      width: 18px;
    }
  }

  .form_error {
    color: red;
    font-size: 9px;
    font-weight: bold;
  }
}

.dialog_content {
  margin-bottom: 18px;
}

.dialog_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > span {
    width: 18px;
  }
}

.mail {
    color: white;
    text-decoration: underline;
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
  .billing {
    max-width: 100%;
    padding-left: 0px;
    .items {
      display: block;

      .item {
        width: 100%;
        margin-bottom: 18px;
      }
    }
  }
  .view_plans{
    .plans{
      display: block;
    }
  }
}