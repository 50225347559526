@import "styles/styles";

 .inner_right{
   margin-left: 30%;
    padding: 72px 128px;
    box-sizing: border-box;
    width: 70%;
    display: inline-block;

   .inner_content{

   }

    .logo{
      width: 101px;
      display: none;
    }
  }


@media screen and (max-width: 1100px){
    .inner_right{
      padding: 64px;
      width: 60%;
      margin-left: 40%;
    }
  }

@media screen and (max-width: $TABLET_BREAKPOINT){
    .inner_right{
      padding: 45px 18px;
      width: 100%;
      margin-left: 0;
      max-height: unset;
      .logo{
        display:block ;
      }
    }
}