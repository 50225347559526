@import "styles/styles";


.file_upload {
  .avatar_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    margin-bottom: 27px;
  }
  .label{
    background-color: transparent;
    color: $TURQUOISE;
    border: 2px solid $TURQUOISE;
    padding: 14px 27px;
    text-transform: uppercase;
    margin-bottom: 36px;
    display: inline-block;
    border-radius: 4px;

  }
  .error {
    color: $ERROR_RED;
    font-size: 0.75rem;
  }
}