@import "styles/styles";


.inner_left{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  background-color: $NAVY;
  width: 30%;
  display: inline-block;
  position: fixed;
  padding: 64px 45px;
  box-sizing: border-box;
  color: $WHITE;

  .logo{
    width: 101px;
  }

}


@media screen and (max-width: 1100px){
    .inner_left{
      width: 40%;
      padding: 45px 36px;
    }
}

@media screen and (max-width: $TABLET_BREAKPOINT){
    .inner_left{
      display: none;
    }
}